import React, { useContext, useEffect } from "react"
import { Button, ProductTooltip, FormLabels } from "../../../components"
import EnergyTooltipInfo from "../../../components/EnergyTooltipInfo"
import {
  calculatePrices,
  toPoundFormat,
  currencyToNumber,
} from "../../../services/utils"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"

import {
  GlobalStateContext,
  GlobalDispatchContext
} from "../../../../../context/GlobalContextProvider"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: "#FAFAFA",
  },

  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "end",
  },
  individualButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "end",
    fontSize: "14px",
    marginTop: "8px"
  },
  priceModeSelect: {
    color: "grey",
    cursor: "pointer",
    margin: "0px 5px",
    textDecorationLine: 'underline'
  },
  activePriceModeSelect: {
    color: "#FAFAFA",
    fontWeight: "bold",
    cursor: "default",
    textDecorationLine: 'none'
  },
  serviceContainer: {},
  services: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 150px)",
    overflowY: "auto",
    "@media only screen and (min-width: 768px)": {
      height: "550px",
    },
  },
  servicesWithoutEndSpace: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
    padding: 20,
    paddingTop: 0,
  },
  service: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nameAndDescription: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  serviceName: {
    width: "90%",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
  },
  serviceDescription: {
    fontSize: "16px",
    fontWeight: "24px",
  },
  servicePriceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginBottom: "12px",
  },
  servicePriceItem: {
    marginTop: "14px",
    textAlign: 'right'
  },
  servicePrice: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
  },
  perPerson: {
    fontSize: "12px",
  },
  free: {
    fontWeight: "bold",
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "4px",
    borderRadius: "4px",
    fontSize: "16px",
    lineHeight: "24px",
    alignSelf: "center",
    marginBottom: "12px",
  },
}))

export default function QuoteSummary({
  quote,
  disabled,
  loading,
  isSummaryPage,
  isMobile,
  onContinue,
  continueButtonText,
  toolElementLayout,
  setCurrentView,
  matchingQuotingToolVersion,
  isHomeownerOrRenter,
}) {
  const styles = useStyles()
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)
  const tenancyDetails = JSON.parse(quote.tenancyDetails)
  const numberOfBillPayers = parseInt(tenancyDetails.NumberOfBillPayers ?? tenancyDetails.NumberOfTenants);

  let unlimitedPackageSelected = false;
  let unlimitedServiceIds = quote.services.filter(_ => _.unlimitedPackage).map(_ => _.id);

  if (JSON.parse(quote.packageDetails).filter(_ => unlimitedServiceIds.find(x => x === _.serviceId)).length > 0) {
    unlimitedPackageSelected = true;
  }

  const { weeklyTotal, monthlyTotal, yearlyTotal, services } = calculatePrices(
    quote,
    matchingQuotingToolVersion,
    quote.tenancyDetails,
    isHomeownerOrRenter,
    unlimitedPackageSelected
  )
  useEffect(() => {
    // This effect runs whenever weeklyTotal, monthlyTotal, or yearlyTotal changes.
    // It updates the total price based on the current priceModeView in the context.

    const priceMap = {
      week: weeklyTotal,
      month: monthlyTotal,
      year: yearlyTotal
    };

    const totalPrice = priceMap[context.priceModeView] || yearlyTotal;

    dispatch({ type: "setTotalPrice", totalPrice: totalPrice });
  }, [weeklyTotal, monthlyTotal, yearlyTotal, context.priceModeView]);



  const quotingToolMultipliers = matchingQuotingToolVersion.quotingToolPages
    .map(quotingToolPage =>
      quotingToolPage.quotingToolPageFields
        .filter(
          quotingToolField => quotingToolField.quotingToolMultipliers.length > 0
        )
        .map(quotingToolField => quotingToolField.quotingToolMultipliers)
    )
    .flat()

  function convertNumberToPrice(number) {
    if (context.individualPriceModeView === "bill-payer")
      return '£' + (number.toLocaleString(undefined, { minimumFractionDigits: 2 }));

    if (context.individualPriceModeView === "household")
      return '£' + (number.toLocaleString(undefined, { minimumFractionDigits: 2 }));
  }
  return (
    <>
      {toolElementLayout && (
        <FormLabels
          label={toolElementLayout.box3HeadingText}
          description={toolElementLayout.box3DescriptionText}
          popupText={toolElementLayout.box3PopupText}
        />
      )}
      <div className={styles.root}>
        <div style={{ "background": "#08033D", "color": "#FAFAFA", "padding": "15px", "width": "100%", "marginTop": "40px", "textAlign": "center", borderRadius: 20 }}>
          <div style={{ fontWeight: 'bold' }}>Book a call with a move specialist!</div>
          <div>Get set up instantly and hear about all our offers.</div>
          <a href="https://calendly.com/the-bunch/speak-to-the-bunch" target="_blank">
            <Button style={{ "background": "#82D0FF", "color": "#08033D", margin: '0 auto', marginTop: 20 }}>Book a call</Button></a>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 20,
            marginTop: 20,
          }}
        >
          <div style={{ fontFamily: "tenon, sans-serif", fontSize: 24 }}>Your breakdown</div>
        </div>

        <div
          className={
            isSummaryPage ? styles.servicesWithoutEndSpace : styles.services
          }
        >
          {services
            .filter(service => service.displayInQuote !== "4. Hidden")
            .sort((x, y) => x.displayOrder - y.displayOrder)
            .map((s, index) => {
              return (
                <div className={styles.serviceContainer}>
                  <div className={styles.service} key={"service" + index}>
                    <div style={{ flexDirection: 'column' }}>
                      <div className={styles.nameAndDescription}>
                        <div className={styles.serviceName}>
                          {s.name} {s.description && <ProductTooltip description={s.description} />}
                          {s.products && s.products[0] && (s.products[0].category === "Electricity" || s.products[0].category === "Gas") &&
                            <Tooltip
                              style={{
                                fontSize: 17,
                                opacity: 1,
                                zIndex: 9999999999,
                              }}
                              title={<h6><EnergyTooltipInfo s={s} tenancyDetails={quote} quotingToolMultipliers={quotingToolMultipliers} /></h6>}
                              placement="top"
                              enterTouchDelay={0}
                            >
                              <InfoIcon style={{
                                fontSize: 15,
                                cursor: "pointer",
                                marginBottom: 4
                              }} />
                            </Tooltip>

                          }                        </div>
                        {s.subName !== null ? (
                          <div className={styles.serviceDescription}>
                            {s.subName}
                          </div>
                        ) : null}
                      </div>
                      <div style={{ marginTop: 20 }}>
                        {s.products && s.products[0] && (s.products[0].category !== "Electricity" && s.products[0].category !== "Gas") &&
                          <EnergyTooltipInfo s={s} tenancyDetails={quote} quotingToolMultipliers={quotingToolMultipliers} />
                        }
                      </div>
                    </div>
                    <div style={{ flexDirection: 'column' }}>
                      <div className={styles.servicePriceContainer}>
                        {s.servicePricePerWeek ? (
                          s.servicePricePerWeek !== "£0.00" && (!unlimitedPackageSelected || !s.freeWithUnlimited) ? (
                            <div className={styles.servicePriceItem}>
                              <div className={styles.servicePrice}>
                                {
                                  context.priceModeView === "week"
                                    ? convertNumberToPrice(currencyToNumber(s.servicePricePerWeek) * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))
                                    : context.priceModeView === "month"
                                      ? convertNumberToPrice(currencyToNumber(s.servicePricePerMonth) * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1)) :
                                      convertNumberToPrice(currencyToNumber(s.servicePricePerYear) * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))}
                                /{context.priceModeView}
                              </div>
                              {numberOfBillPayers > 1 && <div className={styles.perPerson}>{context.individualPriceModeView === "household" ? "per household" : "per bill-payer"}</div>}

                            </div>
                          ) : (
                            <div className={styles.free}>FREE</div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      margin: "30px auto",
                    }}
                  />
                </div>
              )
            })}
          {tenancyDetails.ReferralCode && <div>Your referral code has been accepted. You will receive your reward once your 14 day cooling off period has ended.</div>}
        </div>
        {
          <div style={{ width: '100%', textAlign: 'right' }}>
            <div style={{ fontSize: 40, fontWeight: "bold", margin: "0px 0" }}>
              {context.priceModeView === "month"
                ? toPoundFormat(monthlyTotal * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))
                : context.priceModeView === "year"
                  ? toPoundFormat(yearlyTotal * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))
                  : toPoundFormat(weeklyTotal * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))}/{context.priceModeView}
            </div>
            {numberOfBillPayers > 1 && <div className={styles.perPerson}>{context.individualPriceModeView === "household" ? "per household" : "per bill-payer"}</div>}
            {yearlyTotal > 0 && <div className={styles.perPerson} >VAT included</div>}
          </div>
        }
      </div>
    </>
  )
}
