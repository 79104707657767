import validator from "validator"
import { DateTime } from "luxon"

export function getFormFieldError(formField, values, isHomeownerOrRenter, referralCodeIsValid) {
  const value = values[formField.name]
  const fieldType = formField.type
  const isRequired = formField.required

  if (isRequired && fieldType === "Yes No" && value === undefined) {
    return formField.emptyErrorText
  }

  if (isRequired && fieldType !== "Yes No" && !value) {
    return formField.emptyErrorText
  } else if (!isRequired && !value) {
    return false
  }

  if (
    fieldType === "Date" &&
    formField.name === "MoveInDate" &&
    value &&
    isHomeownerOrRenter
  ) {
    var d = DateTime.fromFormat(value, "yyyy-MM-dd")

    var lastAvailableDate = DateTime.local().plus({ days: 30 })

    if (d > lastAvailableDate) {
      return "We can only accept contracts starting in the next 30 days"
    }
  }

  if (fieldType === "Date" && value) {
    var d = DateTime.fromFormat(value, "yyyy-MM-dd")
    var cutoffDate = DateTime.fromISO("1900-01-01");

    if (!d.isValid) {
      return "Please enter a date in the format DD-MM-YYYY"
    }
    if (d < cutoffDate) {
      return "Please enter a date later than January 1, 1900.";
    }
  }

  if(formField.name === "ReferralCode"){
    if (!referralCodeIsValid.valid && referralCodeIsValid.checked) return "Referral code is no longer valid"
    if (!referralCodeIsValid.valid && !referralCodeIsValid.checked) return "Please validate the referral code"
    return
  }

  if (fieldType === "Address" && (!value.addressLine1 || !value.postCode)) {
    return formField.emptyErrorText
  } else if (fieldType === "Text" && !validateName(value)) {
    return formField.invalidErrorText
  } else if (fieldType === "Phone" && !validateMobilePhone(value)) {
    return formField.invalidErrorText
  } else if (fieldType === "Email" && !validateEmail(value)) {
    return formField.invalidErrorText
  } else if (fieldType === "Number") {
    if (parseInt(value) < formField.minimumValue) {
      return `Please enter a value greater than ${formField.minimumValue - 1}`
    } else if (parseInt(value) > formField.maximumValue) {
      return `Please enter a value less than ${formField.maximumValue + 1}`
    }
  }

  return false
}

export function validateName(name) {
  return (
    name.trim() !== "" &&
    validator.isAlpha(name, "en-GB", {
      ignore: " -",
    })
  )
}

export function validateMaxLength(value, length) {
  return (
    value.length <= length
  )
}

export function validateEmail(email) {
  if (!email) {
    return false
  }

  email = email.trim()
  return validator.isEmail(
    email.replace(
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
      ""
    )
  )
}

export function validateMobilePhone(phoneNumber) {
  phoneNumber = phoneNumber.trim()
  if (phoneNumber && phoneNumber.startsWith("0")) {
    return validator.isMobilePhone(phoneNumber.replace(/ /g, ""), "en-GB")
  } else if (phoneNumber && phoneNumber.startsWith("+")) {
    return validator.isMobilePhone(phoneNumber.replace(/ /g, ""))
  }

  return false
}

export function uniqueMobilePhone(inputtedMobilePhone, existingMobilePhone) {
  if (!inputtedMobilePhone) {
    return false
  }

  if (inputtedMobilePhone && existingMobilePhone) {
    let formattedInputtedMobilePhone = inputtedMobilePhone
      .replace(/^0+/, "")
      .replace(/\+44/g, "")
      .replace(/ /g, "")

    let formattedExistingMobilePhone = existingMobilePhone
      .replace(/^0+/, "")
      .replace(/\+44/g, "")
      .replace(/ /g, "")

    if (formattedInputtedMobilePhone === formattedExistingMobilePhone) {
      return false
    }
  }

  return true
}

export function validatePostcode(postcode) {
  const re = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i
  return re.test(postcode)
}

export function validateEnergySelected(packageDetails) {
  return packageDetails && packageDetails.find(service => service.category === "Energy") != null;
}