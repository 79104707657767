export const convertToCurrency = num => {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}

export const formatCurrency = (amount) => {
  if (amount != null) {
    return `£${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
  return "No data available.";
};