import React, { useEffect, useState, useContext } from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import { DateTime } from "luxon"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

import {
  GlobalDispatchContext,
} from "../../../context/GlobalContextProvider"

import PageWrapper from "../../../components/PageWrapper"

import SuccessImage from "../../../assets/meter-reading-success.png"

import {
  SignUpInformationContainer,
  SignUpChoiceContainer,
  PersonalFormContainer,
  InviteFormContainer,
  TenancyFormContainer,
  SummaryContainer,
} from "./containers"
import CardSection from "./containers/CardSection"
import TopBarContainer from "./containers/TopBarContainer"

import {
  AddressLookup,
  Button,
  Errors,
  FormLine,
  SubTitleText,
  Center,
  Centered1200Wrapper,
  TextWithSubtext,
  FormLabels,
  PageTitle,
} from "../components"
import InputSection from "../components/InputSection"

import {
  useGetGroup,
  useGetLead,
  useSendInviteEmails,
  useMarkAsPaid,
  useAgreedToTerms,
  useSelectedQueueJump,
} from "../services/group"
import { useCreateClientSecret, useSetup } from "../services/payments"
import { getMatchingQuotingToolVersionById } from "../services/utils"

import Wrapper from "../components/Wrapper"

const useStyles = makeStyles({
  checkbox: {
    color: "#000 !important",
    paddingLeft: "0px",
  },
  checkboxLabel: {
    fontSize: "15px",
    fontFamily: "tenon, sans-serif",
    color: "#000 !important",
    "& a": {
      color: "#000 !important",
    },
  },
})

function LeadPage({ location, groupId, toolId, leadId, siteData }) {
  const dispatch = useContext(GlobalDispatchContext)

  const frontEndUrl = window.location.origin

  const agreedToTerms = useAgreedToTerms()
  const selectedQueueJump = useSelectedQueueJump()

  const [tabs, setTabs] = React.useState([
    "details",
    "invite",
    "payment",
  ])
  const [tab, setTab] = React.useState("") // tenancy | personal | invite | payment | complete
  const [queueJump, setQueueJump] = React.useState(false)

  const [universityCity, setUniversityCity] = React.useState("")

  const groupQuery = useGetGroup(groupId)
  const leadQuery = useGetLead(leadId)

  const group =
    groupQuery.data && groupQuery.data.value && groupQuery.data.value.group
  const latestPrices =
    groupQuery.data && groupQuery.data.value && groupQuery.data.value.services
  const lead = leadQuery.data && leadQuery.data.value

  let costPerMonth = 0
  let proRataSignUpFeeToday = 0

  if (groupQuery.data && groupQuery.data.value && groupQuery.data.value) {
    costPerMonth = groupQuery.data.value.costPerMonth
    proRataSignUpFeeToday = groupQuery.data.value.proRataSignUpFeeToday
  }

  useEffect(() => {
    const changeTab = () => {
      if (window.location.hash === "#SignUp-SignUpInformation") {
        setTab("details")
      } else if (window.location.hash.indexOf("#SignUp-Step-0") !== -1) {
        setTab(
          tabs[parseInt(window.location.hash.replace("#SignUp-Step-0", "")) - 1]
        )
      }
    }

    window.addEventListener("popstate", changeTab)

    return () => window.removeEventListener("popstate", () => changeTab)
  }, [tabs])

  useEffect(() => {
    if (lead && group && !tab) {
      if (group.primaryLead.id != leadId) {
        setTabs(["details", "payment"])
        setTab("details")
        return
      } else if (group.quote.numberOfTenants === 1) {
        setTabs(["details", "payment"])
      } else if (lead && lead.queueJump !== undefined) {
        setQueueJump(lead.queueJump)

        if (lead.queueJump) {
          setTabs(["details", "payment"])
        }
      }
      setTab("details")
    }
  }, [lead, group])

  useEffect(() => {
    if (tabs.indexOf(tab) !== -1) {
      window.location.hash = "SignUp-Step-0" + (tabs.indexOf(tab) + 1)
    }
  }, [tab, tabs])

  useEffect(() => {
    if (group && group.quote && group.quote.city) {
      setUniversityCity(group.quote.city)
    }
  }, [group])

  let matchingQuotingToolVersion =
    groupQuery.data &&
    groupQuery.data.value &&
    getMatchingQuotingToolVersionById(
      siteData,
      groupQuery.data.value.group.quote.quotingToolVersionId
    )

  useEffect(() => {
    if (matchingQuotingToolVersion) {
      var head = document.head
      var link = document.createElement("link")

      link.type = "text/css"
      link.rel = "stylesheet"
      link.href = matchingQuotingToolVersion.customCSSURL

      head.appendChild(link)


      const isHomeownerOrRenter =
        matchingQuotingToolVersion.type.includes("Homeowner") ||
        matchingQuotingToolVersion.type.includes("Renter") ||
        matchingQuotingToolVersion.type.includes("Residential")

      if (isHomeownerOrRenter) {
        dispatch({ type: "setPriceModeView", priceModeView: "month" })
      }

      return () => {
        head.removeChild(link)
      }
    }
  }, [matchingQuotingToolVersion])

  if (
    groupQuery.data &&
    groupQuery.data.errors &&
    groupQuery.data.errors.find(error => error === "Quote prices have expired")
  ) {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        toolId={toolId}
        quotePricesExpired={true}
        showTrustpilotWidget={true}
      ></Wrapper>
    )
  }

  if (
    !matchingQuotingToolVersion ||
    !tab ||
    groupQuery.isLoading ||
    leadQuery.isLoading
  ) {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor="#FAFAFA"
        hideHeaderBorder={true}
        logoImageUrl={""}
      >
        <Center>
          <CircularProgress />
        </Center>
      </PageWrapper>
    )
  }

  const isHomeownerOrRenter =
    matchingQuotingToolVersion.type.includes("Homeowner") ||
    matchingQuotingToolVersion.type.includes("Renter") ||
    matchingQuotingToolVersion.type.includes("Residential")

  let hasBroadband = false
  if (latestPrices.services) {
    let broadbandService = latestPrices.services.find(
      service =>
        service.category === "Broadband" &&
        service.displayInQuote === "1. Package Section"
    )

    if (broadbandService != null) {
      let packageDetails = JSON.parse(group.quote.packageDetails)

      if (packageDetails != null) {
        let selectedBroadbandService = packageDetails.find(
          selectedService => selectedService.serviceId === broadbandService.id
        )
        hasBroadband =
          selectedBroadbandService !== undefined &&
          selectedBroadbandService !== null
      }
    }
  }

  const signedUpScreen = (
    <Wrapper
      location={location}
      matchingQuotingToolVersion={matchingQuotingToolVersion}
      group={group}
      toolId={toolId}
      quoteId={group.quote.id}
      quoteSummaryData={{
        services: groupQuery.data.value.services.services,
        tenancyDetails: group.quote.tenancyDetails,
        packageDetails: group.quote.packageDetails,
      }}
      showTrustpilotWidget={true}
      isHomeownerOrRenter={isHomeownerOrRenter}
    >
      <Centered1200Wrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt="Success"
            style={{ height: 60, width: 60 }}
            src={SuccessImage}
          />
        </div>

        <div style={{ color: "#000", padding: 20 }}>
          <div
            style={{
              padding: "10px 0px",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            You're all set!
          </div>
          <Button
            fullWidth
            className={clsx(
              "SignUp-Step-06-Complete",
              "quotingTool-submitButton"
            )}
            onClick={() =>
              window.location.replace(
                frontEndUrl + "/tenants/app/signup/complete/" + groupId
              )
            }
          >
            Return to overview
          </Button>
        </div>
      </Centered1200Wrapper>
    </Wrapper>
  )

  const splitBillsScreen = (
    <Wrapper
      location={location}
      matchingQuotingToolVersion={matchingQuotingToolVersion}
      group={group}
      toolId={toolId}
      quoteId={group.quote.id}
      quoteSummaryData={{
        services: groupQuery.data.value.services.services,
        tenancyDetails: group.quote.tenancyDetails,
        packageDetails: group.quote.packageDetails,
      }}
      showTrustpilotWidget={true}
      isHomeownerOrRenter={isHomeownerOrRenter}
    >
      <Centered1200Wrapper>
        <div style={{ padding: 20, color: "#FAFAFA" }}>
          <div
            style={{
              padding: "10px 0px",
              fontSize: "24px",
              textAlign: "center",
              color: "#FAFAFA",
            }}
          >
            Your account is complete!
          </div>
          <div
            style={{
              padding: "10px 0px",
              fontSize: "16px",
              textAlign: "center",
              marginBottom: 20,
              color: "#FAFAFA",
            }}
          >
            To make sure the bill is split, add your housemates
          </div>
          <Button
            fullWidth
            className={clsx(
              "SignUp-Step-06-Complete",
              "quotingTool-submitButton"
            )}
            onClick={() => setTab("invite")}
          >
            Split my bill
          </Button>
        </div>
      </Centered1200Wrapper>
    </Wrapper>
  )

  if (lead && lead.completed) {
    return signedUpScreen
  }

  if (
    !queueJump &&
    (groupQuery.errors ||
      !groupQuery.data.isSuccess ||
      leadQuery.errors ||
      !leadQuery.data.isSuccess)
  ) {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        Unable to load tenant.
      </Wrapper>
    )
  }

  if (tab === "signUpInformation") {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        <SignUpInformationContainer
          location={location}
          isHomeownerOrRenter={isHomeownerOrRenter}
          onContinue={() => {
            window.scrollTo({ top: 0 })
            setTab("details")
            // if (
            //   group.primaryLead.id === leadId &&
            //   !group.primaryLead.isPartnerPrimaryLead &&
            //   group.quote.numberOfTenants > 1
            // ) {
            //   setTab("details")
            // }
          }}
          queueJump={queueJump}
        />
      </Wrapper>
    )
  }
  if (tab === "details") {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        <TopBarContainer
          isPrimaryLead={
            group.primaryLead.id === leadId &&
            !group.primaryLead.isPartnerPrimaryLead
          }
          isOnlyLead={group.quote.numberOfTenants > 1}
          frontEndUrl={frontEndUrl}
          groupId={groupId}
          currentStep={tabs.indexOf(tab)}
          setCurrentStep={newStepNo => setTab(tabs[newStepNo])}
          queueJump={queueJump}
          isHomeownerOrRenter={isHomeownerOrRenter}
          goToPage={newStepNo => setTab(tabs[newStepNo])}
          progress={0}
        />
        <PersonalInformationTab
          primary={group.primaryLead.id === leadId}
          onContinue={() => {
            window.scrollTo({ top: 0 })
            if (
              group.primaryLead.id === leadId &&
              !group.primaryLead.isPartnerPrimaryLead &&
              group.quote.numberOfTenants > 1
            ) {
              if (queueJump) {
                setTab("summary")
              } else {
                setTab("invite")
              }
            } else {
              setTab("payment")
            }
          }}
          lead={lead}
          group={group}
          universityCity={universityCity}
          setUniversityCity={setUniversityCity}
          isHomeownerOrRenter={isHomeownerOrRenter}
          matchingQuotingToolVersion={matchingQuotingToolVersion}
          toolId={toolId}
          quoteId={group.quote.id}
          quoteSummaryData={{
            services: groupQuery.data.value.services.services,
            tenancyDetails: group.quote.tenancyDetails,
            packageDetails: group.quote.packageDetails,
          }}
          hasBroadband={hasBroadband}
        />
      </Wrapper>
    )
  }
  if (tab === "signUpChoice") {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        <SignUpChoiceContainer
          location={location}
          onContinue={signUpOptionSelected => {
            window.scrollTo({ top: 0 })

            if (signUpOptionSelected === "queue-jump") {
              setQueueJump(true)
              setTabs(["details", "payment"])
              selectedQueueJump({ leadId })
            } else {
              setQueueJump(false)
              setTabs(["details", "invite", "payment"])
            }
            setTab("details")
          }}
          queueJumpFeatures={matchingQuotingToolVersion.queueJumpFeatures}
          standardFeatures={matchingQuotingToolVersion.standardFeatures}
        />
      </Wrapper>
    )
  }
  if (tab === "invite" && group.primaryLead.id === leadId) {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        {!queueJump && (
          <TopBarContainer
            isPrimaryLead={
              group.primaryLead.id === leadId &&
              !group.primaryLead.isPartnerPrimaryLead &&
              group.quote.numberOfTenants > 1
            }
            isOnlyLead={group.quote.numberOfTenants > 1}
            frontEndUrl={frontEndUrl}
            groupId={groupId}
            currentStep={tabs.indexOf(tab)}
            setCurrentStep={newStepNo => setTab(tabs[newStepNo])}
            queueJump={queueJump}
            goToPage={newStepNo => setTab(tabs[newStepNo])}
            progress={1}
          />
        )}
        <InviteHousematesTab
          onContinue={() => {
            window.scrollTo({ top: 0 })
            if (queueJump) {
              navigate(`/tenants/app/signup/complete/${group.id}/success`)
            } else {
              setTab("payment")
            }
          }}
          group={group}
          groupId={groupId}
          queueJump={queueJump}
          setTab={setTab}
          isHomeownerOrRenter={isHomeownerOrRenter}
        />
      </Wrapper>
    )
  }
  if (tab === "summary") {
    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        <TopBarContainer
          isPrimaryLead={
            group.primaryLead.id === leadId &&
            !group.primaryLead.isPartnerPrimaryLead
          }
          isOnlyLead={group.quote.numberOfTenants > 1}
          frontEndUrl={frontEndUrl}
          groupId={groupId}
          currentStep={tabs.indexOf(tab)}
          setCurrentStep={newStepNo => setTab(tabs[newStepNo])}
          queueJump={queueJump}
          goToPage={newStepNo => setTab(tabs[newStepNo])}
          progress={2}
        />
        <SummaryContainer
          onContinue={() => setTab("payment")}
          quote={{
            id: group.quote.id,
            services: latestPrices.services,
            tenancyDetails: group.quote.tenancyDetails,
            packageDetails: group.quote.packageDetails,
            quotingToolVersionId: group.quote.quotingToolVersionId,
          }}
          queueJump={queueJump}
          matchingQuotingToolVersion={matchingQuotingToolVersion}
          group={group}
          toolId={toolId}
          quoteId={group.quote.id}
          quoteSummaryData={{
            services: groupQuery.data.value.services.services,
            tenancyDetails: group.quote.tenancyDetails,
            packageDetails: group.quote.packageDetails,
          }}
          isHomeownerOrRenter={isHomeownerOrRenter}
        />
      </Wrapper>
    )
  }
  if (tab === "payment") {
    if (lead.customerID === "00000000-0000-0000-0000-000000000000") {
      return (
        <Wrapper
          location={location}
          matchingQuotingToolVersion={matchingQuotingToolVersion}
          group={group}
          toolId={toolId}
          quoteId={group.quote.id}
          quoteSummaryData={{
            services: groupQuery.data.value.services.services,
            tenancyDetails: group.quote.tenancyDetails,
            packageDetails: group.quote.packageDetails,
          }}
          showTrustpilotWidget={true}
          isHomeownerOrRenter={isHomeownerOrRenter}
        >
          <Center>
            <CircularProgress />
          </Center>
        </Wrapper>
      )
    }

    return (
      <Wrapper
        location={location}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        group={group}
        toolId={toolId}
        quoteId={group.quote.id}
        quoteSummaryData={{
          services: groupQuery.data.value.services.services,
          tenancyDetails: group.quote.tenancyDetails,
          packageDetails: group.quote.packageDetails,
        }}
        showTrustpilotWidget={true}
        isHomeownerOrRenter={isHomeownerOrRenter}
      >
        <TopBarContainer
          isPrimaryLead={
            group.primaryLead.id === leadId &&
            !group.primaryLead.isPartnerPrimaryLead
          }
          isOnlyLead={group.quote.numberOfTenants > 1}
          frontEndUrl={frontEndUrl}
          groupId={groupId}
          currentStep={tabs.indexOf(tab)}
          setCurrentStep={newStepNo => setTab(tabs[newStepNo])}
          queueJump={queueJump}
          goToPage={newStepNo => setTab(tabs[newStepNo])}
          progress={3}
        />
        <PaymentTab
          group={group}
          lead={lead}
          costPerMonth={costPerMonth}
          proRataSignUpFeeToday={proRataSignUpFeeToday}
          queueJump={queueJump}
          onContinue={() => {
            agreedToTerms({ leadId })
            if (
              queueJump &&
              group.quote.numberOfTenants > 1 &&
              group.primaryLead.id === leadId
            ) {
              setTab("splitBills")
            } else {
              navigate(`/tenants/app/signup/complete/${group.id}/success`)
            }
          }}
          matchingQuotingToolVersion={matchingQuotingToolVersion}
        />
      </Wrapper>
    )
  }
  if (tab === "splitBills") {
    return splitBillsScreen
  }
  if (tab === "complete") {
    return signedUpScreen
  }
}

function PersonalInformationTab({
  lead,
  onContinue,
  group,
  primary,
  universityCity,
  setUniversityCity,
  isHomeownerOrRenter,
  matchingQuotingToolVersion,
  hasBroadband,
}) {
  const [showLockedInAddress, setShowLockedInAddress] = React.useState(
    group.quote.postCode
  )

  const [tenancyInformation, setTenancyInformation] = React.useState({
    startDate:
      group.tenancyStartDate && group.tenancyStartDate !== "0001-01-01T00:00:00"
        ? DateTime.fromISO(group.tenancyStartDate).toFormat("yyyy-MM-dd")
        : null,
    endDate:
      group.tenancyEndDate && group.tenancyEndDate !== "0001-01-01T00:00:00"
        ? DateTime.fromISO(group.tenancyEndDate).toFormat("yyyy-MM-dd")
        : null,
    agencyId: group.agencyId || null,
    agency: group.agency,
    lettingAgencyName: group.lettingAgencyName,
    isTenancyStartDateEstimate: group.isTenancyStartDateEstimate,
    isTenancyEndDateEstimate: group.isTenancyEndDateEstimate,
    address: {
      addressLine1: group.quote.addressLine1,
      addressLine2: group.quote.addressLine2,
      city: group.quote.city,
      postCode: group.quote.postCode,
      country: group.quote.country,
      manuallyEntered: group.quote.manuallyEntered,
    },
  })

  const isStudent =
    matchingQuotingToolVersion.type === "Student" || lead.type === "student"

  const [personalInformation, setPersonalInformation] = React.useState({
    dateOfBirth: lead.birthday,
    studentBool: isStudent,
    graduationYear: lead.graduationYear,
    moveInDate:
      DateTime.fromISO(group.tenancyStartDate) >
        DateTime.fromISO(lead.moveInDate)
        ? DateTime.fromISO(group.tenancyStartDate).toFormat("yyyy-MM-dd")
        : DateTime.fromISO(lead.moveInDate).toFormat("yyyy-MM-dd"),
    moveInDateEstimate: lead.moveInDateEstimate,
    firstName: lead.firstName,
    lastName: lead.lastName,
    phoneNumber: lead.phone,
    email: lead.email,
    university: lead.university,
    isVulnerablePerson: lead.isVulnerablePerson,
    psrCategory: lead.psrCategory,
    psrAuthorisedContactName: lead.psrAuthorisedContactName,
    psrAuthorisedContactEmail: lead.psrAuthorisedContactEmail,
    psrAuthorisedContactDateOfBirth: lead.psrAuthorisedContactDateOfBirth
  })

  useEffect(() => {
    if (tenancyInformation.address) {
      setUniversityCity(tenancyInformation.address.city)
    }
  }, [tenancyInformation.address, setUniversityCity])

  return (
    <PersonalFormContainer
      city={universityCity}
      primary={primary}
      personalInformation={personalInformation}
      onContinue={onContinue}
      group={group}
      lead={lead}
      setPersonalInformation={update =>
        setPersonalInformation(h => ({ ...h, ...update }))
      }
      tenancyInformation={tenancyInformation}
      setTenancyInformation={update =>
        setTenancyInformation(h => ({ ...h, ...update }))
      }
      showLockedInAddress={showLockedInAddress}
      setShowLockedInAddress={setShowLockedInAddress}
      isHomeownerOrRenter={isHomeownerOrRenter}
      hasBroadband={hasBroadband}
    />
  )
}

function InviteHousematesTab({
  group,
  onContinue,
  groupId,
  queueJump,
  setTab,
  isHomeownerOrRenter,
}) {
  const [inviteDetails, setInviteDetails] = React.useState(
    Array(group.quote.numberOfTenants - 1).fill({
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      isMissingInformation: false,
    })
  )

  React.useEffect(() => {
    const priorInvites = group.otherLeads.map(lead => ({
      firstName: lead.firstName,
      lastName: lead.lastName,
      email: lead.email,
      number: lead.phone,
      isMissingInformation: lead.isMissingInformation,
    }))

    setInviteDetails(
      priorInvites.concat(
        Array(group.quote.numberOfTenants - 1 - priorInvites.length).fill({
          firstName: "",
          lastName: "",
          email: "",
          number: "",
          isMissingInformation: false,
        })
      )
    )
  }, [group.otherLeads, group.quote.numberOfTenants])

  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState([""])
  const sendInviteEmails = useSendInviteEmails()

  function buttonOnClick() {
    if (submitting) {
      return false
    }

    setSubmitting(true)
    setErrors(null)
    sendInviteEmails({
      groupId: group.id,
      invites: inviteDetails.map(lead => {
        return {
          email: lead.email && lead.email.toLowerCase().trim(),
          number: lead.number,
          firstName: lead.firstName,
          lastName: lead.lastName,
          isMissingInformation: lead.isMissingInformation,
        }
      }),
    }).then(r => {
      if (r.isSuccess) {
        setSubmitting(false)
        setTab("payment")
        onContinue()
      } else {
        setSubmitting(false)
        setErrors(r.errors)
      }
    })
  }

  return (
    <InviteFormContainer
      inviteDetails={inviteDetails}
      setInviteDetails={e => setInviteDetails(e)}
      groupId={groupId}
      groupLeadEmail={group.primaryLead.email}
      groupLeadPhoneNumber={group.primaryLead.phone}
      buttonOnClick={buttonOnClick}
      buttonSubmitting={submitting}
      errors={errors}
      isHomeownerOrRenter={isHomeownerOrRenter}
    />
  )
}

function PaymentTab({
  lead,
  group,
  onContinue,
  queueJump,
  matchingQuotingToolVersion,
  costPerMonth,
  proRataSignUpFeeToday,
}) {
  const markPaid = useMarkAsPaid(lead.id)
  const [agreed, setAgreed] = useState(false)

  React.useEffect(() => {
    document.title = "Bunch - Payment Details"
  }, [])

  const [houseDetails, setHouseDetails] = React.useState({
    address: "",
  })

  const emptyErrorState = {
    postcode: {
      error: false,
      helperText: "",
    },
    postcodeLookup: {
      error: false,
      helperText: "",
    },
    addressLine1: {
      error: false,
      helperText: "",
    },
    city: {
      error: false,
      helperText: "",
    },
    country: {
      error: false,
      helperText: "",
    },
  };

  const [validationErrors, setValidationErrors] = React.useState(emptyErrorState)

  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState([""])

  const [paymentName, setPaymentName] = React.useState("")
  const getClientSecret = useCreateClientSecret(lead.customerID)
  const setup = useSetup()
  const classes = useStyles()

  const [personalInformation, setPersonalInformation] = React.useState({
    dateOfBirth: lead.birthday,
    isVulnerablePerson: lead.isVulnerablePerson,
    psrCategory: lead.psrCategory,
    psrAuthorisedContactName: lead.psrAuthorisedContactName,
    psrAuthorisedContactEmail: lead.psrAuthorisedContactEmail,
    psrAuthorisedContactDateOfBirth: lead.psrAuthorisedContactDateOfBirth
  })

  const validateFieldsOnSubmit = newErrors => {
    if (!houseDetails.address) {
      setErrors(["Please search for an address"])
      newErrors.postcodeLookup = {
        error: true,
        helperText: "Please search for an address",
      }
    }

    if (houseDetails.address.addressLine1 === "") {
      setErrors(["Address line 1 is required"])
      newErrors.addressLine1 = {
        error: true,
        helperText: "Address line 1 is required",
      }
    }

    if (houseDetails.address.city === "") {
      setErrors(["Town is required"])
      newErrors.city = { error: true, helperText: "Town is required" }
    }

    if (houseDetails.address.postCode === "") {
      setErrors(["Postcode is required"])
      newErrors.postcode = { error: true, helperText: "Postcode is required" }
    }

    setValidationErrors(newErrors)

    console.log("newErrors", newErrors)
    return newErrors
  }

  const currentDate = new Date()
  const startDate = new Date(group.tenancyStartDate)

  const chargeNow =
    currentDate > startDate || currentDate.getMonth() == startDate.getMonth()

  return (
    <div style={{ minHeight: "calc(10up0vh - 384px)" }}>
      <Centered1200Wrapper>
        <div style={{
          padding: "20px 20px 0px"
        }}>
          <PageTitle
            style={{ textAlign: "left" }}
          >
            <div className="quotingTool-pageTitle">
              {matchingQuotingToolVersion.paymentPageTitle || "Card Details"}
            </div>
          </PageTitle>
          {matchingQuotingToolVersion.paymentPageDescription && (
            <div
              style={{
                fontWeight: "400",
                color: "#000",
                opacity: "0.54",
                marginBottom: 10,
              }}
              className="quotingTool-pageDescription"
            >
              {matchingQuotingToolVersion.paymentPageDescription}
            </div>
          )}
          <div
            style={{
              fontWeight: "400",
              color: "#000",
              opacity: "0.54",
              marginBottom: 20,
            }}
            className="quotingTool-pageDescription"
          >
            {chargeNow ? (
              <div>
                <div style={{ marginBottom: 10 }}>
                  Please enter your billing details. You will be charged £
                  {proRataSignUpFeeToday.toFixed(2)} today, which is a portion
                  of this month's payment according to your start date.
                </div>
                <div>
                  Future payments of £{costPerMonth.toFixed(2)} will be due on
                  the 1st of the month, payable via this card.
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: 10 }}>
                  Please enter your billing details, you will not be charged, but your card will be stored to be used when your contract begins.
                </div>
                <div>
                  Future payments of £{costPerMonth.toFixed(2)} will be due on
                  the 1st of the month, payable via this card.
                </div>
              </div>
            )}
          </div>
        </div>

        <FormLine>
          <FormLabels popupText="Any problems, call us on the live chat below" />
          <div style={{ width: "100%" }}>
            <CardSection />
          </div>
        </FormLine>

        <FormLine>
          <InputSection
            label="Cardholder Name"
            placeholder="Cardholder Name"
            type="text"
            value={paymentName}
            onChange={e => setPaymentName(e.target.value)}
          />
        </FormLine>

        <FormLine>
          <AddressLookup
            label="Billing Address*"
            placeholder="Billing Address"
            value={houseDetails.address}
            onChange={payload => {
              setHouseDetails({ ...houseDetails, address: payload })
            }}
            required={true}
          />
        </FormLine>
        <div
          style={{
            display: "flex",
            flexDirection: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 40,
            marginBottom: 20,
            padding: "0 20px",
          }}
        >
          <Checkbox
            color="primary"
            id="agree"
            name="agree"
            checked={agreed}
            value={agreed}
            onChange={e => {
              setAgreed(e.target.checked)
            }}
            className={classes.checkbox}
          />
          <div
            htmlFor="agree"
            style={{
              fontSize: "15px",
              fontFamily: "tenon, sans-serif",
              color: "#000",
            }}
            className={classes.checkboxLabel}
          >
            I agree to Bunch{" "}
            <a
              href="https://www.the-bunch.co.uk/terms-conditions"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#000", textDecorationLine: 'underline' }}
            >
              terms
            </a>{" "}
            and{" "}
            <a
              href="https://www.the-bunch.co.uk/privacy-policy"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#000", textDecorationLine: 'underline' }}
            >
              privacy policy
            </a>
          </div>
        </div>
        <div style={{ padding: "0 20px" }}>
          <Errors style={{ marginBottom: 20 }}>
            {errors && errors.map(e => <>{e}</>)}
          </Errors>
          <Button
            className={clsx(
              "SignUp-Step-05-PaymentDetails",
              "quotingTool-submitButton"
            )}
            fullWidth
            disabled={!agreed || submitting}
            showShine
            onClick={() => {
              if (submitting) {
                return false
              }

              setErrors(null)
              const newErrors = validateFieldsOnSubmit(
                emptyErrorState
              )
              let hasError = false
              Object.values(newErrors).forEach(value => {
                if (value.error) {
                  hasError = true
                }
              })

              if (hasError) {
                return false
              }

              setSubmitting(true)
              getClientSecret(lead.customerID).then(result => {
                setup({
                  customerId: lead.customerID,
                  clientSecret: result.value,
                  name: paymentName,
                  address: houseDetails.address,
                  dateOfBirth: personalInformation.dateOfBirth,
                  isVulnerablePerson: personalInformation.isVulnerablePerson,
                  psrCategory: personalInformation.psrCategory,
                  psrAuthorisedContactName: personalInformation.psrAuthorisedContactName,
                  psrAuthorisedContactEmail: personalInformation.psrAuthorisedContactEmail,
                  psrAuthorisedContactDateOfBirth: personalInformation.psrAuthorisedContactDateOfBirth
                }).then(r2 => {
                  if (r2.isSuccess) {
                    setSubmitting(false)
                    try {
                      markPaid({ leadId: lead.id })
                    } catch {
                      //ignore
                    }
                    onContinue()
                  } else if (r2.error) {
                    setSubmitting(false)
                    setErrors([`Your payment has failed. ${r2.error}`])
                  } else {
                    setSubmitting(false)
                    setErrors([
                      "Your payment has failed. Please try again, or contact us for assistance.",
                    ])
                  }
                })
              })
            }}
          >
            {submitting ? "Saving..." : "Pay"}
          </Button>
        </div>
      </Centered1200Wrapper>
    </div>
  )
}

export default LeadPage
