import React, { useEffect, useContext, useCallback, useState } from "react"
import { navigate } from "gatsby"
import * as Sentry from "@sentry/react"

import {
  getMyTenancy as apiGetMyTenancy,
  getTenancyMeters as apiGetTenancyMeters,
} from "../../services/tenantsApiV2"

import { GlobalStateContext } from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import AppTitle from "../../components/appTitle/AppTitle"
import SubmitMeterReading from "../../components/submitMeterReading/SubmitMeterReading"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"
import FAQSection from "../../components/faqSection/FAQSection"

import * as tenancyDetailsStyles from "./tenancyDetails.module.css"

const faqContent = {
  title: "FAQs for submitting meter readings",
  description:
    "Check out our FAQs we have put together to support you whilst submitting your meter readings.",
  questionsAndAnswers: [
    {
      question: "What are meter readings for?",
      answer: [
        "Meter readings are to ensure that we are getting you the best prices from our energy suppliers. Without meter readings, suppliers send bills based on estimates which can often end in overcharging.",
      ],
    },
    {
      question: "Where is my meter?",
      answer: [
        "Your meters are usually located outside – on the front or back of your property. Occasionally, in older properties or in flats/apartments, your meters will be inside and can be found in a basement or discrete cupboard of a property. If you are unable to find your meters please contact your landlord or letting agent who will know where they are.",
      ],
    },
    {
      question: "How often do you need meter reads?",
      answer: [
        "You will be asked to give meter readings monthly. The more readings we get the more accurate we can be with your usage statements.",
      ],
    },
    {
      question:
        "I forgot to read the meter before I left for the summer. What should I do?",
      answer: [
        "Your energy company will have readings from previous tenants in the property and should be able to calculate a relatively good estimate, however it can be inaccurate. Please contact your landlord to see if they have taken the readings for you, or if they are able to do so. Get back to us as soon as you can!",
      ],
    },
  ],
}

export default ({ location, tenancyId }) => {
  const context = useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(
    false
  )
  const [meters, storeMeters] = React.useState([])
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)
  const [openSection, setOpenSection] = useState(faqContent.title)
  const [openQuestion, setOpenQuestion] = useState(0)

  const getMeterReadings = async () => {
    try {
      setGettingTenancyDetails(true)
      Sentry.captureMessage(`Getting meters for tenancy ${tenancyId}`)

      const data = await apiGetTenancyMeters(tenancyId)

      Sentry.captureMessage(
        "Received the following response getting meters: " +
        JSON.stringify(data)
      )

      if (data && data.value) {
        Sentry.captureMessage(`There are ${data.value.length} meters to show`)
        storeMeters(data.value)
      }
    } catch (e) {
      console.log(e)
      Sentry.captureException(e)
      Sentry.captureMessage(
        "An error occurred getting meters, with the error message: " +
        e.toString()
      )
    }
    setGettingTenancyDetails(false)
  }

  const getTenancyDetails = useCallback(async () => {
    getMeterReadings()
  }, [tenancyId])

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      Sentry.captureMessage(`Getting tenancy details for id ${tenancyId}.`)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value) {
        storeTenancy(data.value)
        getTenancyDetails()
      }
    } catch (e) {
      console.log(e)
      Sentry.captureException(e)
      Sentry.captureMessage(
        "An error occurred getting user tenancy, with the error message: " +
        e.toString()
      )
      setGetMyTenancyError(e.message)
    }
    setGettingTenancies(false)
  }, [tenancyId, getTenancyDetails])

  useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancies || gettingTenancyDetails}
      showLoadingText="Just getting your meters"
      tenancy={tenancy}
      // Hide sidebar links if the only have the role to upload meter readings
      hideSidebarLinks={context.loggedInToTenantsPortalForMeterReadings}
    >
      <div className={tenancyDetailsStyles.tenancyDetails}>
        <AppTitle
          showBackHome={!context.loggedInToTenantsPortalForMeterReadings}
          title="Meter Reading"
        />
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {tenancy && (
          <>
            <div>
              {meters.map(meter => (
                <Frame
                  title={`${meter.type.replace(
                    "Economy 7",
                    "Electric"
                  )} Readings`}
                >
                  <div style={{ marginBottom: 20 }}>
                    <div>
                      <b>Meter ID:</b> {meter.meterSerialNumber}
                    </div>
                    <div>
                      <b>Type:</b>{" "}
                      {meter.type.replace("Economy 7", "Electric (Economy 7)")}
                    </div>
                  </div>
                  <SubmitMeterReading
                    tenancy={tenancy}
                    tenancyId={tenancyId}
                    meter={meter}
                    onSubmit={() => getMeterReadings()}
                  />
                </Frame>
              ))}
              <Frame>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <div style={{ fontSize: 20, marginBottom: 10 }}>
                    Looking for some help?
                  </div>
                  <div>
                    Try reading the FAQs below.
                  </div>
                </div>
              </Frame>

              <FAQSection
                section={faqContent}
                openSection={openSection}
                setOpenSection={section => setOpenSection(section)}
                openQuestion={openQuestion}
                setOpenQuestion={question => setOpenQuestion(question)}
              />
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  )
}
