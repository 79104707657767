import React, { useState, useCallback, useContext } from "react"
import Frame from "../../components/frame/Frame"
import Frames from "../../components/frames/Frames"
import PageWrapper from "../../components/PageWrapper"
import InvoiceSummary from "../../components/invoiceSummary/InvoiceSummary"
import {
    getTenancyOverview as apiGetTenancyOverview,
    getInvoices as apiGetInvoices,
} from "../../services/tenantsApiV2"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import * as overviewStyles from "./overview.module.css"

export default function LatestInvoices({ location, tenancyId }) {
    const context = useContext(GlobalStateContext)

    const [tenancy, storeTenancy] = useState(null)
    const [gettingTenancyDetails, setGettingTenancyDetails] = useState(true)
    const [invoices, storeInvoices] = useState([])
    const [sortOrder, setSortOrder] = useState("desc");


    const getTenancyDetails = useCallback(async () => {
        try {
            setGettingTenancyDetails(true)
            const data = await Promise.all([
                apiGetTenancyOverview(tenancyId),
                apiGetInvoices(tenancyId),
            ])

            if (data[0] && data[0].value) {
                storeTenancy(data[0].value.tenancy)
            }

            if (data[1] && data[1].value) {
                storeInvoices(data[1].value)
            }
        } catch (e) {
            console.log(e)
        }
        setGettingTenancyDetails(false)
    }, [tenancyId])

    React.useEffect(() => {
        getTenancyDetails()
    }, [context.loggedIn])

    const toggleSortOrder = (e) => {
        setSortOrder(e.target.value);
    };

    const sortedInvoices = invoices.sort((a, b) => {
        return sortOrder === "asc"
            ? new Date(a.invoiceDate) - new Date(b.invoiceDate)
            : new Date(b.invoiceDate) - new Date(a.invoiceDate);
    });


    return (
        <PageWrapper
            location={location}
            showSidebar={true}
            showPortalHeader={true}
            hideHeader={true}
            showLoadingIndicator={gettingTenancyDetails}
            showLoadingLogo={true}
            showLoadingText="Getting your live data"
            tenancy={tenancy}
        >
            <div>
                <Frame title="Latest/Past Invoices">
                    {sortedInvoices.length > 1 && <div style={{ marginBottom: 20, display: "flex", gap: "10px", height: "35px", alignItems: "center" }}>
                        <label htmlFor="sortOrder" style={{ alignSelf: "center", marginBottom: 0 }}>Sort by date: </label>
                        <select id="sortOrder" value={sortOrder} onChange={toggleSortOrder}
                            style={{
                                padding: '6px 10px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                color: '#333',
                                cursor: 'pointer',
                                alignSelf: "center"
                            }}>
                            <option value="desc">Newest first</option>
                            <option value="asc">Oldest first</option>
                        </select>
                    </div>}

                    <div className={overviewStyles.invoices}>
                        {sortedInvoices.map(invoice => (
                            <InvoiceSummary invoice={invoice} />
                        ))}
                    </div>
                </Frame>
            </div>
        </PageWrapper>
    )
}